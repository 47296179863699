<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center"> </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="agent-user-dealer-orders"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    :detail="detailTemplate"
                    @sortchange="sortChange"
                >
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="OrderStatuses"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template v-slot:has_contract_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('has_contract', $event.value.value)
                            "
                        >
                        </boolean-filter>
                    </template>
                    <template v-slot:status="{ methods, props: { dataItem } }">
                        <td>
                            {{
                                getProductOrderStatusNameByKey(dataItem.status)
                            }}
                        </td>
                    </template>
                    <template v-slot:has_contract="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success':
                                            Boolean(+dataItem.has_contract),
                                        'm-badge--danger':
                                            !Boolean(+dataItem.has_contract),
                                    }"
                                >
                                    {{ Boolean(+dataItem.has_contract) ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper">
                            <v-tabs v-model="tab">
                                <v-tab key="details" href="#details"
                                    >Подробная информация</v-tab
                                >
                                <v-tab key="order-items" href="#order-items"
                                    >Состав заказа</v-tab
                                >
                                <v-tab key="client" href="#client"
                                    >Клиент</v-tab
                                >
                                <v-tab key="history" href="#history"
                                    >История</v-tab
                                >
                                <v-tab key="comment" href="#comment"
                                    >Комментарий</v-tab
                                >
                                <v-tabs-items v-model="tab">
                                    <v-tab-item key="details" id="details">
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            <div>
                                                <table
                                                    class="details-table table table-striped m-table"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td>Номер:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.number
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Дата оплаты:
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.date
                                                                            ? moment(
                                                                                  dataItem.date
                                                                              ).format(
                                                                                  'DD.MM.YYYY'
                                                                              )
                                                                            : ''
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Статус:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        getProductOrderStatusNameByKey(
                                                                            dataItem.status
                                                                        )
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Дилер:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.dealer_name
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Сумма:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.amount
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Адрес доставки:
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.delivery_address
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr></tr>
                                                        <tr>
                                                            <td>
                                                                Договор
                                                                заключен?:
                                                            </td>
                                                            <td>
                                                                <span
                                                                    class="m-badge m-badge--wide m-badge--wide"
                                                                    :class="{
                                                                        'm-badge--success':
                                                                            dataItem.has_contract,
                                                                        'm-badge--danger':
                                                                            !dataItem.has_contract,
                                                                    }"
                                                                >
                                                                    {{
                                                                        dataItem.has_contract
                                                                            ? 'ДА'
                                                                            : 'НЕТ'
                                                                    }}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                class="font-weight-bold"
                                                                colspan="2"
                                                            >
                                                                <b
                                                                    >Контактное
                                                                    лицо</b
                                                                >
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>ФИО:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.contact_person_name
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>E-mail:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem.dealer_email
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-container>
                                    </v-tab-item>
                                    <v-tab-item
                                        key="order-items"
                                        id="order-items"
                                    >
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            <div
                                                v-if="
                                                    dataItem.allow_editing &&
                                                    (dataItem.editing ||
                                                        (dataItem.items &&
                                                            dataItem.items
                                                                .length >= 1))
                                                "
                                                class="order-item-actions"
                                            >
                                                <template
                                                    v-if="!dataItem.editing"
                                                >
                                                    <CButton
                                                        @click="
                                                            editOrderPositions(
                                                                dataItem
                                                            )
                                                        "
                                                        class="edit-order-btn"
                                                        color="info"
                                                    >
                                                        Редактировать
                                                    </CButton>
                                                </template>
                                                <template v-else>
                                                    <CButton
                                                        :disabled="
                                                            !dataItem.deletedItems ||
                                                            !dataItem
                                                                .deletedItems
                                                                .length
                                                        "
                                                        @click="
                                                            currentItem =
                                                                dataItem;
                                                            showOrderPositionDeletingModal = true;
                                                        "
                                                        class="edit-order-btn mr-3"
                                                        color="info"
                                                    >
                                                        Cохранить
                                                    </CButton>

                                                    <CButton
                                                        @click="
                                                            cancelOrderPositionEditing(
                                                                dataItem
                                                            )
                                                        "
                                                        class="edit-order-btn"
                                                        color="danger"
                                                    >
                                                        Отменить
                                                    </CButton>
                                                </template>
                                            </div>

                                            <div
                                                v-if="
                                                    dataItem.items &&
                                                    dataItem.items.length
                                                "
                                            >
                                                <table role="presentation">
                                                    <thead role="presentation">
                                                        <tr
                                                            role="row"
                                                            aria-rowindex="1"
                                                        >
                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                            >
                                                                Товар
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>
                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                                style=""
                                                            >
                                                                Цена
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>

                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="3"
                                                                style=""
                                                            >
                                                                Количество
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <table
                                                    class="k-grid-table order-item-table"
                                                    role="presentation"
                                                    style="
                                                        transform: translateY(
                                                            0px
                                                        );
                                                    "
                                                >
                                                    <tbody role="presentation">
                                                        <tr
                                                            v-for="(
                                                                item, key
                                                            ) in dataItem.items"
                                                            class="k-alt"
                                                            data-kendo-grid-item-index="0"
                                                            role="row"
                                                            aria-rowindex="2"
                                                        >
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                            >
                                                                <i
                                                                    v-if="
                                                                        dataItem.editing
                                                                    "
                                                                    @click="
                                                                        removeOrderPosition(
                                                                            dataItem,
                                                                            key
                                                                        )
                                                                    "
                                                                    class="la la-times remove-order-item"
                                                                ></i>
                                                                {{
                                                                    item.product_name
                                                                }}
                                                            </td>
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                            >
                                                                {{ item.price }}
                                                            </td>
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="3"
                                                            >
                                                                {{
                                                                    item.quantity
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-container>
                                    </v-tab-item>
                                    <v-tab-item key="client" id="client">
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            <div>
                                                <table
                                                    class="table table-striped m-table"
                                                >
                                                    <tbody>
                                                        <tr
                                                            style="
                                                                visibility: hidden;
                                                            "
                                                        >
                                                            <td
                                                                width="200px"
                                                            ></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                class="font-weight-bold"
                                                                colspan="2"
                                                            >
                                                                <b> Профиль </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="200px">
                                                                Наименомание:
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .name
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>КССС:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .unique_id
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table
                                                    class="table table-striped m-table"
                                                >
                                                    <tbody>
                                                        <tr
                                                            style="
                                                                visibility: hidden;
                                                            "
                                                        >
                                                            <td
                                                                width="200px"
                                                            ></td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                class="font-weight-bold"
                                                                colspan="2"
                                                            >
                                                                <b
                                                                    >Договор с
                                                                    агентством</b
                                                                >
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Номер:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .contract_agency_number
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Дата:</td>
                                                            <td>
                                                                {{
                                                                    dataItem
                                                                        .dealer
                                                                        .contract_agency_date
                                                                        ? moment(
                                                                              dataItem
                                                                                  .dealer
                                                                                  .contract_agency_date
                                                                          ).format(
                                                                              'DD.MM.YYYY'
                                                                          )
                                                                        : ''
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table
                                                    class="table table-striped m-table"
                                                >
                                                    <tbody>
                                                        <tr
                                                            style="
                                                                visibility: hidden;
                                                            "
                                                        >
                                                            <td
                                                                width="200px"
                                                            ></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                class="font-weight-bold"
                                                                colspan="2"
                                                            >
                                                                <b
                                                                    >Контактное
                                                                    лицо</b
                                                                >
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>ФИО:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .contact_person_name
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Должность:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .contact_person_position
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Телефон:</td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .contact_person_phone
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="200px">
                                                                E-mail:
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    {{
                                                                        dataItem
                                                                            .dealer
                                                                            .email
                                                                    }}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-container>
                                    </v-tab-item>
                                    <v-tab-item key="history" id="history">
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            <div>
                                                <table role="presentation">
                                                    <thead role="presentation">
                                                        <tr
                                                            role="row"
                                                            aria-rowindex="1"
                                                        >
                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                                style="
                                                                    width: 48%;
                                                                "
                                                            >
                                                                Дата<span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                >
                                                                </span>
                                                            </th>
                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                            >
                                                                Статус
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                >
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <table
                                                    class="k-grid-table"
                                                    role="presentation"
                                                    style="
                                                        transform: translateY(
                                                            0px
                                                        );
                                                    "
                                                >
                                                    <tbody role="presentation">
                                                        <tr
                                                            v-for="item in dataItem.status_history"
                                                            class="k-alt"
                                                            data-kendo-grid-item-index="0"
                                                            role="row"
                                                            aria-rowindex="2"
                                                        >
                                                            <td
                                                                aria-selected="false"
                                                                role="gridcell"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                                style="
                                                                    width: 48%;
                                                                "
                                                            >
                                                                {{
                                                                    moment(
                                                                        item.datetime
                                                                    ).format(
                                                                        'DD.MM.YYYY HH:mm:ss'
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                aria-selected="false"
                                                                role="gridcell"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                            >
                                                                {{
                                                                    getProductOrderStatusNameByKey(
                                                                        item.status
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-container>
                                    </v-tab-item>
                                    <v-tab-item key="comment" id="comment">
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            {{ dataItem.comment }}
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </div>
                    </template>
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end">
                            <datepicker
                                :default-show="defaultShow"
                                :start-date-input-settings="startDateInputSettings"
                                :end-date-input-settings="endDateInputSettings"
                                :value="value"
                                @change="dateChangeHandler"
                            />
                            <button @click="clearDates" title="Clear" type="button" class="k-button k-button-icon k-clear-button-visible"><span class="k-icon k-i-filter-clear"></span></button>
                        </div>
                        
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    :disabled="
                                        !dataItem.can_change_status_by_agent
                                    "
                                    @click="
                                        currentItem = dataItem;
                                        showEntityModal = true;
                                    "
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-pencil"></i>
                                    Изменить статус
                                </CButton>
                            </div>
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <change-status-modal
            order-type="dealer"
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></change-status-modal>
        <order-position-deleting-modal
            order-type="dealer"
            v-if="showOrderPositionDeletingModal"
            :current-item="currentItem"
            :show-dialog="showOrderPositionDeletingModal"
            @click:outside="showOrderPositionDeletingModal = false"
            @stored="
                showOrderPositionDeletingModal = false;
                updateTable();
            "
            @close="showOrderPositionDeletingModal = false"
        ></order-position-deleting-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import BooleanFilter from '@/views/components/BooleanFilter';
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import '@/views/grid_elements/translations';
    import api from '@/api';
    import OrderStatuses from '@/config/order_statuses';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import ChangeOrderStatusModal from '@/views/content/agent-user/modals/ChangeOrderStatusModal';
    import ApproveOrderPositionDeletingModal from '@/views/content/agent-user/modals/ApproveOrderPositionDeletingModal';
    import { showConfirm } from '@/lib/swal2';
    import { error } from '@/lib/toasted';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';
    import { DateRangePicker } from '@progress/kendo-vue-dateinputs';

    export default {
        name: 'ProductOrders',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            'boolean-filter': BooleanFilter,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            ActionsDropdown,
            'change-status-modal': ChangeOrderStatusModal,
            'order-position-deleting-modal': ApproveOrderPositionDeletingModal,
            datepicker: DateRangePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            await this.getData();
            this.fetchDealerListShort();
        },
        data: function () {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                expandedItems: [],
                gridPageable: { pageSizes: true },
                value: {
                    start: null,
                    end: null,
                },
                defaultShow: false,
                startDateInputSettings: {
                    label: 'От',
                    format: 'dd.MM.yyyy',
                },
                endDateInputSettings: {
                    format: 'dd.MM.yyyy',
                    label: 'До',
                },
                detailTemplate: 'detail',
                sort: [{ field: 'date', dir: 'desc' }],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 150,
                        width: '150px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'date',
                        title: 'Дата оплаты',
                        filter: 'date',
                        type: 'date',
                        format: '{0:d}',
                        minWidth: 400,
                        width: '400px',
                        filterCell: 'date_filter',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        filterCell: 'status_filter',
                        cell: 'status',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'amount',
                        title: 'Сумма',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'has_contract',
                        title: 'Договор заключен?',
                        filterCell: 'has_contract_filter',
                        cell: 'has_contract',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        title: 'Контактное лицо',
                        minWidth: 480,
                        width: '480px',
                        children: [
                            {
                                field: 'contact_person_name',
                                title: 'ФИО',
                                width: '200px',
                                type: 'text',
                                filter: 'text',
                            },
                            {
                                field: 'dealer_email',
                                title: 'Е-маил',
                                width: '280px',
                                type: 'text',
                                filter: 'text',
                            },
                        ],
                    },
                    {
                        title: 'E-mail получателя',
                        minWidth: 250,
                        width: '250px',
                        field: 'recipient_email',
                        filter: 'text',
                    },
                    {
                        title: 'Телефон получателя',
                        minWidth: 250,
                        width: '250px',
                        field: 'recipient_phone',
                        filter: 'text',
                    }
                ],
                tab: null,
                OrderStatuses,
                showOrderPositionDeletingModal: false,
            };
        },
        methods: {
            ...mapActions('account', ['loginAsAgent']),
            ...mapActions('agent_user_orders', ['fetchDealerListShort']),
            fetchData: api.orders.getAgentUserDealerProductOrders,
            async prepareData() {
                try {
                    const fetchParams = this.prepareFetchParams();
                    const {
                        data: { data },
                    } = await this.fetchData(...fetchParams);

                    data.items = data.items.map(item => {
                        item.initialItems = [...item.items];
                        return item;
                    });

                    return data;
                } catch (e) {}
            },
            dateChangeHandler({ value }) {
                this.value.start = value.start
                this.value.end = value.end
                if (value.start || value.end) {
                    const dateStart = new Date(value.start).toLocaleDateString(
                        'ru-RU'
                    );
                    const dateEnd = new Date(value.end).toLocaleDateString(
                        'ru-RU'
                    );
                    if (dateEnd === '01.01.1970') {
                        this.setFilter('date', 'gte', dateStart);
                    } else if (dateStart === dateEnd) {
                        this.removeFilter('date');
                        this.setFilter('date', 'gtedatetime', dateStart);
                    } else {
                        this.removeFilter('date');
                        const obj = [
                            {
                                field: 'date',
                                operator: 'gte',
                                value: dateStart + 'T00:00:00',
                            },
                            {
                                field: 'date',
                                operator: 'lte',
                                value: dateEnd + 'T23:59:59',
                            },
                        ];
                        this.setMultipleFilter('date', obj);
                    }
                } else {
                    this.removeFilter('dealer_id');
                }
                this.updateTable(true);
            },
            clearDates() {
                this.value.start = null
                this.value.end = null
                this.clearFilters()
                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key);
                } else {
                    this.removeFilter('status');
                }

                this.updateTable(true);
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            editOrderPositions(item) {
                if (item.items.length == 1) {
                    error(
                        'Данный заказ нельзя редактировать. Для удаления позиции заказ можно отменить.'
                    );
                    return;
                }

                this.$set(item, 'editing', true);
            },
            async removeOrderPosition(item, index) {
                if (item.items.length == 1) {
                    error('Нельзя удалить последнюю позицию заказа.');
                    return;
                }

                const { isConfirmed } = await showConfirm(
                    'Вы уверены, что хотите удалить товар из заказа?'
                );

                if (isConfirmed) {
                    let items = item.items;
                    let deletedItems = item.deletedItems || [];

                    deletedItems.push(items[index].id);
                    items.splice(index, 1);

                    Object.assign(item, {
                        items: [...items],
                        deletedItems: [...deletedItems],
                    });
                }
            },
            cancelOrderPositionEditing(order) {
                Object.assign(order, {
                    editing: false,
                    items: [...order.initialItems],
                    deletedItems: [],
                });
            },
        },
        computed: {
            ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
            ...mapGetters('agent_user_orders', ['dealersListShort']),
        },
    };
</script>

<style lang="sass" scoped>
    .details-table::v-deep
      tr
        td
          &:nth-child(1)
            width: 200px

    .actions-btn
      white-space: normal
      padding: 3px 10px !important

      &.btn::after
        content: none !important


    .order-item-actions
      margin-top: 3px
      margin-bottom: 13px

      .edit-order-btn
        font-size: 12px
        padding: 0.3rem 0.8rem

    .order-item-table
      .remove-order-item
        cursor: pointer
        color: #060a13
        font-size: 16px
        font-weight: bold
        margin-right: 8px
</style>
