<template>
    <CDropdown
            color="primary"
            toggler-text=""
            size="sm"
            addTogglerClasses="actions-btn btn-square btn-info"
            placement="bottom-end"
            :customPopperOptions="{container: 'body'}"
            v-bind="$props"
    >
        <template v-for="(action, key) in actions" >
            <CDropdownItem @click="action.handler" :key="key" >
                <div>
                    <template v-if="action.icon" >
                        <i class="dropdown-item__icon" :class="{[action.icon]: action.icon}" ></i>
                        <span class="dropdown-item__text">{{action.text}}</span>
                    </template>
                </div>
            </CDropdownItem>
        </template>
    </CDropdown>
</template>

<script>
    export default {
        name: "actions-dropdown",
        props: {
            actions: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
